<template>
	<div class='page-personal-file'>
		<div class="file-header text-center">
			<div class="header-text">
				{{studentName + fileText.headertext}}
			</div>
		</div>
		<div class="file-content">
			<div class="content-item"  v-for="(item , index) in archiveInfoOfV_for" :key="index">
				<div class="item-top">
					<div class="top-icon"></div>
					<div class="top-text">
						{{item.headText}}
					</div>
					<div class="addtxt-right" v-show="index == 1">&nbsp;- 第{{puzzleSumExerciseCount}}关</div>
					<div class="addtxt-left text-small" v-show="index == 0">{{item.showType}}</div>
				</div>
				<div class="item-middle">
					<div class="left">
						<div class="left-item "  @click="getMaxPercentage(index)">
							<img :src="`${PARAMS.assetsUrl}/img/wht/buttonCheckout.png`" alt="" v-if="item.showType != item.currentBottonType">
							<img :src="`${PARAMS.assetsUrl}/img/wht/buttonCheckoutActive.png`" alt=""  v-if="item.showType == item.currentBottonType">
							<div class="left-item-text text-small"
								v-bind:class="{ 'button-active': item.showType == item.currentBottonType }">
								{{fileText.middleTopText}}
							</div>
							
						</div>
						<div class="left-item" @click="changePercentageInfo(item.defaultType,index)">
							<img :src="`${PARAMS.assetsUrl}/img/wht/buttonCheckout.png`" alt="" v-if="item.defaultType != item.currentBottonType">
							<img :src="`${PARAMS.assetsUrl}/img/wht/buttonCheckoutActive.png`" alt=""  v-if="item.defaultType == item.currentBottonType">
							<div class="left-item-text text-small"
								v-bind:class="{ 'button-active': item.defaultType == item.currentBottonType }">
								{{'近' + item.defaultType + item.lastWord}}
							</div>
							
						</div>
						<div class="left-item" @click="changePercentageInfo(item.secondType,index)">
							<img :src="`${PARAMS.assetsUrl}/img/wht/buttonCheckout.png`" alt="" v-if="item.secondType != item.currentBottonType">
							<img :src="`${PARAMS.assetsUrl}/img/wht/buttonCheckoutActive.png`" alt=""  v-if="item.secondType == item.currentBottonType">
							<div class="left-item-text text-small"
								v-bind:class="{ 'button-active': item.secondType == item.currentBottonType }">
								{{'近' + item.secondType + item.lastWord}}
							</div>
							
						</div>
					</div>
					<div class="right">
						<div class="pieChart">
							<personalPieChart :percentageInfo="item.percentageInfo" :idName="index"></personalPieChart>
						</div>
						<div class="rate">
							<div class="rate-big">{{item.percentageInfo}}</div>
							<div class="rate-small text-small">%</div>
						</div>
						<div class="rate-text text-mini">
							{{item.percentageText}}
						</div>
					</div>
				</div>
				<div class="item-bottom">
					<div class="bottom-item">
						<div class="top">
							<div class="big-text">{{item.recentSevenDayMsg}}</div>
							<div class="small-text">{{item.lastWord}}</div>
						</div>
						<div class="bottom">{{fileText.bottomLefttext}}</div>
					</div>
					<div class="bottom-item">
						<div class="top">
							<div class="big-text">{{item.recentThirtyDayMsg}}</div>
							<div class="small-text">{{item.lastWord}}</div>
						</div>
						<div class="bottom">{{fileText.bottomMiddletext}}</div>
					</div>
					<div class="bottom-item">
						<div class="top">
							<div class="big-text">{{item.allDaysMsg}}</div>
							<div class="small-text">{{item.lastWord}}</div>
						</div>
						<div class="bottom">{{fileText.bottomRighttext}}</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import api from '@/API/page/personal'
	export default {
		name: '',
		props: {},
		data() {
			return {
				api: new api(),
				appData: new this.appDataClass({key: 'personal'}),
				studentName:'刘一01',
				studentCode:'',
				// 最大的题数和局数和课后习题数
				maxPuzzleNum:0,
				maxCourseNum:0,
				maxAINum:0,
				defaultBottonAI:5,
				defaultBottonPuzzle:50,
				// 闯关数
				puzzleSumExerciseCount:0,
				fileText:{
					headertext:this.pageText['t1'],
					bottomLefttext:this.pageText['t7'],
					bottomMiddletext:this.pageText['t8'],
					bottomRighttext:this.pageText['t9'],
					middleTopText:this.pageText['t12'],
				},
				// 封装的组件
				archiveInfoOfV_for:[
					{
						// 标题
						headText:this.pageText['t5'],
						// 量词
						lastWord:'局',
						// 近7天的局
						recentSevenDayMsg:0,
						recentThirtyDayMsg:0,
						allDaysMsg:0,
						// 当前选择的近xx啥的button的索引
						currentBottonType:this.pageText['t12'],
						// 传给子组件的百分数
						percentageInfo:80,
						// 百分比的描述
						percentageText:this.pageText['t10'],
						defaultType:5,
						secondType:20,
						showType:this.pageText['t12']
					},
					{
						// 标题
						headText:this.pageText['t6'],
						// 量词
						lastWord:'题',
						// 近7天的局
						recentSevenDayMsg:0,
						recentThirtyDayMsg:0,
						allDaysMsg:0,
						// 当前选择的近xx啥的button的索引
						currentBottonType:this.pageText['t12'],
						// 传给子组件的百分数
						percentageInfo:75,
						// 百分比的描述
						percentageText:this.pageText['t11'],
						defaultType:50,
						secondType:100,
						showType:this.pageText['t12']
					},
					
				],
			};
		},
		methods: {
			async getPersonalUser(studentName){
				// 初始化页面数据
				let res = await this.api.getPersonalUser(studentName)
				if(res){
					this.studentName = res.username
					this.studentCode = res.userCode
					this.getPersonalAI(this.defaultBottonAI,0)
					this.getPersonalPuzzle(this.defaultBottonPuzzle,0)
				}
				
			},
			async getPersonalAI(curAIType,isInitial){
				if(isInitial != 0){
					this.archiveInfoOfV_for[0].currentBottonType = curAIType
					this.archiveInfoOfV_for[0].showType ='近' + curAIType + this.archiveInfoOfV_for[0].lastWord
				}
				let res = await this.api.getPersonalAI(this.studentCode,curAIType)
				if(res){
					this.maxAINum = res.gameCount
					this.archiveInfoOfV_for[0].recentSevenDayMsg = res.gameD07Count
					this.archiveInfoOfV_for[0].recentThirtyDayMsg = res.gameD30Count
					this.archiveInfoOfV_for[0].allDaysMsg = res.gameCount
					this.archiveInfoOfV_for[0].percentageInfo = parseInt(res.winRate * 100)
				}
			},
			async getPersonalAIMax(curAIType){
				this.archiveInfoOfV_for[0].currentBottonType = this.pageText['t12']
				this.archiveInfoOfV_for[0].showType = this.pageText['t12']
				let res = await this.api.getPersonalAI(this.studentCode,curAIType)
				if(res){
					this.maxAINum = res.gameCount
					this.archiveInfoOfV_for[0].recentSevenDayMsg = res.gameD07Count
					this.archiveInfoOfV_for[0].recentThirtyDayMsg = res.gameD30Count
					this.archiveInfoOfV_for[0].allDaysMsg = res.gameCount
					this.archiveInfoOfV_for[0].percentageInfo = parseInt(res.winRate * 100)
				}
			},
			async getPersonalPuzzle(curCourseType,isInitial){
				if(isInitial != 0){
					this.archiveInfoOfV_for[1].currentBottonType = curCourseType
					this.archiveInfoOfV_for[1].showType ='近' + curCourseType + this.archiveInfoOfV_for[1].lastWord
				}
				let res = await this.api.getPersonalPuzzle(this.studentCode,curCourseType)
				if(res){
					this.maxPuzzleNum = res.userExerciseCount
					this.puzzleSumExerciseCount = res.exerciseCount
					this.archiveInfoOfV_for[1].recentSevenDayMsg = res.userExerciseD07Count
					this.archiveInfoOfV_for[1].recentThirtyDayMsg = res.userExerciseD30Count
					this.archiveInfoOfV_for[1].allDaysMsg = res.userExerciseCount
					this.archiveInfoOfV_for[1].percentageInfo = parseInt(res.userExerciseCorrectRate * 100) 
					
				}
			},
			async getPersonalPuzzleMax(curCourseType){
				this.archiveInfoOfV_for[1].currentBottonType = this.pageText['t12']
				this.archiveInfoOfV_for[1].showType = this.pageText['t12']
				let res = await this.api.getPersonalPuzzle(this.studentCode,curCourseType)
				if(res){
					// 最大值
					this.maxPuzzleNum = res.userExerciseCount
					this.puzzleSumExerciseCount = res.exerciseCount
					this.archiveInfoOfV_for[1].recentSevenDayMsg = res.userExerciseD07Count
					this.archiveInfoOfV_for[1].recentThirtyDayMsg = res.userExerciseD30Count
					this.archiveInfoOfV_for[1].allDaysMsg = res.userExerciseCount
					this.archiveInfoOfV_for[1].percentageInfo = parseInt(res.userExerciseCorrectRate * 100) 
				}
			},
			// 获取全部的百分比
			getMaxPercentage(index){
				// 先把数组中的三个相关的变量进行更新
				if(index == 0){
					this.getPersonalAIMax(this.maxAINum)
				}
				else if(index == 1){
					this.getPersonalPuzzleMax(this.maxPuzzleNum)
				}
				
			},
			// 获取近xx局百分比
			changePercentageInfo(curType,index){
				if(index == 0){
					this.getPersonalAI(curType,1)
				}
				else if(index == 1){
					this.getPersonalPuzzle(curType,1)
				}
				
				
			},
		},
		created() {
			this.getPersonalUser('123456')
		},
		components: {
		},
		computed: {},
		watch: {},
		mounted() {},
		beforeCreate() {}, //生命周期 - 创建之前
		beforeMount() {}, //生命周期 - 挂载之前
		beforeUpdate() {}, //生命周期 - 更新之前
		updated() {}, //生命周期 - 更新之后
		beforeDestroy() {}, //生命周期 - 销毁之前
		destroyed() {}, //生命周期 - 销毁完成
		activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
	}
</script>

<style lang='less' scoped>
	.page-personal-file{
		// font-family: PingFangSC-Semibold, PingFang SC;
		height: 100%;
		width: 100%;
		position: absolute;
		margin-left: -3px;
		.file-header{
			height: 22px;
			margin-top: 5px;
			margin-right: 50px;
			line-height: 22px;
			.header-text{
				font-size: 16px;
				font-weight: 600;
				color: #FFFFFF;
			}
		}
		.file-content{
			margin-top: 22px;

			height: 267px;
			width: calc(100% - 50px);
			background: #FFFFFF;
			border-radius: 12px;
			padding: 19px 7px 7px 13px;
			display: flex;
			overflow: hidden;
			.content-item{
				width: calc(calc(100% - 19px) / 2);
				height: 100%;
				margin-right: 19px;
				flex-shrink: 0;
				.item-top{ 
					height: 26px;
					width: 100%;
					display: flex;
					position: relative;
					flex-wrap: wrap;
					.top-icon{
						width: 6px;
						height: 16px;
						margin: 2px 4px 0px 3px;
						background: linear-gradient(360deg, #FFC1C7 0%, #FF5865 72%, #FF3741 100%);
						border-radius: 3px;
					}
					.top-text{
						height: 20px;
						font-size: 14px;
						font-weight: 600;
						color: #333333;
						line-height: 20px;
					}
					.addtxt-left{
						position: absolute;
						left: 81px;
						top: 14px;
						height: 12px;
						font-weight: 400;
						color: #7DB5FF;
						line-height: 12px;
					}
					.addtxt-right{
						height: 20px;
						font-size: 14px;
						font-weight: 400;
						color: #333333;
						line-height: 20px;
					}
				}
				.item-middle{
					margin: 19px 0px 28px 0px;
					padding-left: 3px;
					width: 100%;
					height: 100px;
					display: flex;
					
					.left{
						width: 62px;
						height: 100px;
						display: flex;
						flex-wrap: wrap;
						align-content: space-between;
						.left-item{
							width: 62px;
							height: 24px;
							position: relative;
							color: #7DB5FF;
							
							.left-item-text{
								height: 24px;
								width: 54px;
								font-size: 12px;
								line-height: 24px;
								font-weight: 400;
								position: absolute;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
								text-align: center;
							}
							img{
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%,-50%);
								width: 62px;
								height: 24px;
								
							}
						}
						
						
					}
					.right{
						margin-left: 23px;
						height: 100px;
						width: 100px;
						position: relative;
						.pieChart{
							height: 100px;
							width: 100px;
							
						}
						.rate{
							height: 24px;
							position: absolute;
							top: 58px;
							left: 50%;
							transform: translateX(-50%);
							display: flex;
							text-align: center;
							align-items: flex-end;
							.rate-big{
								height: 24px;
								line-height: 24px;
								font-size: 24px;
								font-weight: 600;
								
							}
							.rate-small{
								height: 12px;
								line-height: 12px;
								font-weight: 400;
							}
						}
						.rate-text{
							text-align: center;
							height: 12px;
							position: absolute;
							top: 82px;
							left: 50%;
							transform: translateX(-50%);
							font-weight: 400;
							color: #FFFFFF;
							line-height: 12px;
						}
					}
				}
				.item-bottom{
					height: 68px;
					width: 100%;
					background: #F2F2F2;
					border-radius: 8px;
					padding: 16px 23.5px 16px 20px;
					display: flex;
					justify-content: space-between;
					.bottom-item{
						height: 100%;
						width: 57px;
						text-align: center;
						.top{
							height: 20px;
							display: flex;
							align-items: flex-end;
							justify-content: center;
							color: #333333;
							.big-text{
								height: 20px;
								line-height: 20px;
								font-size: 20px;
								font-weight: 600;
							}
							.small-text{
								height: 12px;
								line-height: 12px;
								font-size: 12px;
								font-weight: 400;
							}
						}
						.bottom{
							margin-top: 4px;
							font-weight: 400;
							color: #919191;
							line-height: 12px;
							font-size: 12px;
						}
					}
					
				}
			}
		}
	}
	.button-active{
		color: #FFFFFF;
	}
</style>
